.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1%;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 70%;
}

.Profile-header {
    text-align: left;
    padding-left: 3%;
    align-items: center;
    flex-direction: column;
    max-height: 120px;
    overflow: hidden;
}

.Profile-photo {
    display: flex;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
  

.Full-name {
    font-weight: 500;
    color: white;
    font-size: 28px;
}

.Degree-text {
    color: gray;
    font-size: 14px;
}