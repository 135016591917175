.Icons {
    background-color: #282c34;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 1%;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 70%;
    overflow: hidden;
}

.Icon-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 50px;
    padding-left: 20px;
}

.Text-properties {
    color: inherit;
    text-decoration: none;
}