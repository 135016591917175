.InfoBlock {
    background-color: white;
    border-radius: 10px;
    height: 70vh;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease;
    box-sizing: border-box;
    box-shadow: 0 0 0 7px #282c34;
    cursor: pointer;
  }
  
  .InfoBlock.hovered {
    transform: scale(1.05);
  }
  
  .Icon {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 30%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .Header-text {
    font-size: 50px;
    font-weight: 600;
    padding-bottom: 20px;
    background-color: #282c34;
    text-align: center;
    color: white;
    width: 100%;
    height: 65px;
  }
  
  .Body-text {
    height: 200px;
    width: 80%;
    text-align: left;
    padding-top: 10px;
    font-weight: 600;
    overflow: hidden;
  }
  
  .Attribution {
    text-align: center;
    width: 90%;
  }
  